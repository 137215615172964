<script>
	import * as THREE from 'three';
	import * as SC from 'svelte-cubed';

	let spin = 0;
	let isMousedown = false;

	SC.onFrame(() => {
		if (!isMousedown) {
			spin += 0.005;
		}
	});

	const toggleSpin = () => {
		isMousedown = !isMousedown;
	};
</script>

<svelte:window on:pointerdown={toggleSpin} on:pointerup={toggleSpin}></svelte:window>

<SC.Canvas alpha antialias >
		<SC.Mesh  geometry={new THREE.BoxGeometry()} rotation={[0, spin, 0]} />
		<SC.PerspectiveCamera position={[1, 1, 3]} />
		<SC.OrbitControls enableZoom={false} />
		<SC.DirectionalLight intensity={0.6} position={[-2, 3, 2]} />
		<SC.AmbientLight intensity={1} />
</SC.Canvas>

<style>
	:global(canvas) {
		cursor: pointer;
	}
</style>